<script>
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      isLoading: false,
      commissionType: 'percentage',
      store: {
        affiliation_options: {
          status: null,
          default_commission_percentage: null,
          default_commission_amount: null,
          min_payout_amount: null,
          min_payout_delay: null,
          max_tracking_duration: null,
          referer_priority: null,
          description: null,
        },
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    authUser() {
      return this.$store.getters['auth/user'];
    },
    form() {
      return {
        min_payout_amount: {
          label: 'Montant minimum de règlement',
          type: 'number',
          column: 'is-12',
          tooltip: `
            Lorsque les commissions dûes dépassent ce montant, votre affilié
            peut demander tout ou partie du règlement de ses commissions
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
          inputAttrs: {
            min: 1,
            required: true,
          },
        },
        min_payout_delay: {
          label: 'Délai minimum avant demande de règlement (en jours)',
          type: 'number',
          column: 'is-12',
          tooltip: `
            C'est le délai minimum entre la vente (via le lien d'affiliation)
            et l'exigibilité du règlement de la commission associée
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
          inputAttrs: {
            min: 0,
            required: true,
          },
        },
        max_tracking_duration: {
          label: 'Durée de validité du lien de tracking (forfait EXPERT)',
          type: 'number',
          column: 'is-12',
          tooltip: `
            Lorsque le lien affilié génère une visite, nous attribuons
            la vente à cet affilié si cette dernière intervient pendant
            la durée de validité du tracking
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
          inputAttrs: {
            min: 1,
            required: true,
            disabled: !this.$store.getters['auth/hasExpertPlan'],
          },
        },
        referer_priority: {
          type: 'select',
          column: 'is-12',
          label: 'Règle d\'attribution des ventes (pack EXPERT)',
          options: {
            first: 'Premier lien d\'affiliation cliqué',
            last: 'Dernier lien d\'affiliation cliqué',
          },
          tooltip: `
            Si une vente est issue de plusieurs liens d'affiliation,
            nous attribuons la vente à l'un de ces liens uniquement,
            selon la règle que vous aurez définie
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
          inputAttrs: {
            required: true,
            disabled: !this.$store.getters['auth/hasExpertPlan'],
          },
        },
        description: {
          type: 'editor',
          column: 'is-12',
          label: 'Décrivez votre programme d\'affiliation (facultatif)',
          tooltipHTML: `
            <p>
              Si votre programme d'affiliation est
              "${this.$t('affiliates.status.PUBLIC')}",
              et que vous avez des règles ou instructions particulières
              concernant celui-ci, vous pouvez les préciser ici.
            </p>
            <p class="mt-3">
              Ces informations seront affichées sur le formulaire
              d'inscription de votre programme d'affiliation.
            </p>
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
        },
        default_commission_percentage: {
          label: 'Pourcentage rétribué à l\'affilié',
          type: 'number',
          column: 'is-12',
          tooltip: `
            C'est le pourcentage que vous devrez reverser à votre affilié
            en cas de vente via son lien d'affiliation
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
          inputAttrs: {
            min: 1,
            max: 100,
            step: 0.01,
            required: true,
          },
        },
        default_commission_amount: {
          label: 'Montant rétribué à l\'affilié',
          type: 'number',
          column: 'is-12',
          tooltip: `
            C'est le montant fixe que vous devrez reverser à votre affilié
            en cas de vente via son lien d'affiliation.
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
          inputAttrs: {
            min: 1,
            step: 0.01,
            required: true,
          },
        },
      };
    },
    statusFields() {
      return {
        status: {
          label: 'Statut',
          type: 'detailedselect',
          options: [
            {
              value: 'CLOSED',
              label: this.$t('affiliates.status.CLOSED'),
              details: this.$t('affiliates.status_description.CLOSED'),
            },
            {
              value: 'PRIVATE',
              label: this.$t('affiliates.status.PRIVATE'),
              details: this.$t('affiliates.status_description.PRIVATE'),
            },
            {
              value: 'PUBLIC',
              label: this.$t('affiliates.status.PUBLIC'),
              details: this.$t('affiliates.status_description.PUBLIC'),
            },
          ],
          inputAttrs: {
            required: true,
          },
        },
      };
    },
    commissionTypeField() {
      return {
        type: 'select',
        label: 'Type de commission',
        options: {
          percentage: 'Pourcentage',
          amount: 'Montant fixe',
        },
        inputAttrs: {
          required: true,
        },
      };
    },
    baseFields() {
      const fields = [
        'min_payout_amount',
        'min_payout_delay',
        'max_tracking_duration',
        'referer_priority',
      ];

      if (this.store.affiliation_options.status === 'PUBLIC') {
        fields.push('description');
      }

      return pick(this.form, fields);
    },
    commissionFields() {
      const commissionField = this.commissionType === 'percentage'
        ? 'default_commission_percentage'
        : 'default_commission_amount';

      return pick(this.form, [
        commissionField,
      ]);
    },
    registerURL() {
      const { location: { protocol } } = window;
      const url = `${protocol}${this.$store.getters['auth/url']}`;
      return `${url}/inscription/affiliation`;
    },
  },
  methods: {
    processHandleData(newStore) {
      if (this.commissionType === 'percentage') {
        newStore.affiliation_options.default_commission_amount = 0;
      } else {
        newStore.affiliation_options.default_commission_percentage = 0;
      }

      if (
        newStore.affiliation_options.status !== 'PUBLIC'
        || newStore.affiliation_options.description === ''
      ) {
        newStore.affiliation_options.description = null;
      }

      return newStore;
    },
  },
  created() {
    this.store = cloneDeep(this.store);

    if (this.store.affiliation_options.default_commission_amount) {
      this.commissionType = 'amount';
    } else {
      this.commissionType = 'percentage';
    }
  },
};
</script>

  <template>
    <section>
      <div
        v-if="$store.getters['auth/isAffiliationPublic']"
        class="box"
      >
        <h2 class="title is-5">
          Page d'inscription à votre programme d'affiliation
        </h2>

        <a
            :href="registerURL"
            v-clipboard:copy="registerURL"
            v-clipboard:success="() => $copySuccess()"
            v-clipboard:error="() => $copyError()"
            @click.prevent
        >
          <b-tooltip label="cliquez pour copier">
            <b-icon icon="clipboard" />
          </b-tooltip>
        </a>
        <a
            :href="registerURL"
            target="_blank"
            rel="noopener noreferrer"
        >
          {{ registerURL }}
        </a>
      </div>

      <div class="columns">
        <div class="column is-4 content pr-10 is-small">
          <h2 class="is-size-5">
            Affiliation
          </h2>
          <p>
            L'affiliation vous permet de <strong>vous faire connaître
            et de vendre plus de formations</strong> grâce à la puissance des recommandations.
          </p>
          <p>
            En activant votre propre programme d'affiliation,
            vous récompensez les personnes qui génèrent des ventes pour vous
            en leur <strong>rétribuant un pourcentage des revenus générés</strong> !
          </p>
          <p>
            Ici, vous pouvez créer le programme d'affiliation qui vous ressemble
            grâce aux différents paramétrages.
          </p>
        </div>
        <div class="column">
          <form
            class="box"
            @submit.prevent="dataIsValid(isLoading) && handle()"
          >
            <div class="columns is-multiline">
              <div
                v-for="(field, k) in statusFields"
                :key="k"
                class="column"
                :class="field.column"
              >
                <BaseField
                  v-model="store.affiliation_options[k]"
                  :field="field"
                />
              </div>
              <div class="column is-12">
                <BaseField
                  v-model="commissionType"
                  :field="commissionTypeField"
                />
              </div>
              <div
                v-for="(field, k) in commissionFields"
                :key="k"
                class="column"
                :class="field.column"
              >
                <BaseField
                  v-model="store.affiliation_options[k]"
                  :field="field"
                />
              </div>
              <div
                v-for="(field, k) in baseFields"
                :key="k"
                class="column"
                :class="field.column"
              >
                <BaseField
                  v-model="store.affiliation_options[k]"
                  :field="field"
                />
              </div>
            </div>
            <b-field class="has-text-right">
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isLoading"
              >
                Mettre à jour
              </b-button>
            </b-field>
          </form>
        </div>
      </div>
    </section>
  </template>
